<script>
import { Bar } from 'vue-chartjs'
import axios from '@/http'
import moment from 'moment'

export default {
  name: 'GraficoBarrasCentroCustos',
  extends: Bar,
  data () {
    return {
      lista: [],
      labels: [],
      recebidas: [],
      pagar: []
    }
  },
  methods: {
    async getData() {
      let URL = '/dashboard/graficoccusto'
      URL += '?database=' + this.moment(new Date()).format('YYYY-MM-DD')
      await axios.get(`${URL}`)
          .then(response => {
              this.lista = response.data.data
              this.labels = this.lista.map(function(e) { return e.ccst_descricao; } );
              this.recebidas = this.lista.map(function(e) { return e.recebidas; } );
              this.pagas = this.lista.map(function(e) { return e.pagas; } );
              this.renderGraph();
          })
          .catch(response => {
          })
    },
    renderGraph(){
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
              label: 'Contas Recebidas',
              backgroundColor: '#1FC178',
              data: this.recebidas
          },        
          {
              label: 'Contas Pagas',
              backgroundColor: '#f87979',
              data: this.pagas
          },
        ]
      }, 
      {
          labels: { 
            display: false 
          },
          responsive: true, 
          maintainAspectRatio: false, 
          legends: {
            display: false
          },
          scales:{
            xAxes: [{
              display: false //this will remove all the x-axis grid lines
            }]
          }        
      })
    }
  },
  sockets: {
    async mctatufinanceiro (data) {
      await this.getData()
    }
  },
  async mounted () {
    await this.getData();
  }
}
</script>

<style scoped>
  #bar-chart{
    /*max-width: 300px;*/
    max-height: 350px;
  }
</style>